export const COMMUNITY_FULL_FRAGMENT = `
fragment communityFullFragment on Community {
    uid
    code
    shortName
    timeZone
    longName
    description
    matomoIdSite
    advertisementNetworkId
    landingCSSConfig
    masterCommunity {
      masterEditions {
        code
        edition {
          name
          startTime
          endTime
          venue {
            address {
              city
              state
            }
          }
        }
      }
    }
    logoFileResource {
      schemaCode
      path
    }
    favIconFileResource {
      schemaCode
      path
    }
    defaultExhibitorLogoFileResource {
      path
      schemaCode
    }
    defaultExhibitorBannerFileResource {
      path
      schemaCode
    }
    defaultSessionBannerFileResource {
      path
      schemaCode
    }
    defaultUserBannerFileResource {
      path
      schemaCode
    }
    defaultDealBannerFileResource {
      path
      schemaCode
    }
    organizer {
      uid
      name
      shortname
      email
      secondaryEmail
      salesFulfillmentEmail
      registrationWebsite
      itunesApplicationName
      extraITunesapplicationName
      editionITunesLink
      editionAndroidLink
    }
    termsOfService
    privacyPolicy
}
`;
